import { Heading, Paragraph } from "@aidnas/design-system";
import { PortableText } from "next-sanity";

import Box from "../Box";

import { Status } from "./components/Status";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { AccessibilityTable as AccessibilityTableType } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function AccessibilityTable({ accessibilityTable }: AccessibilityTableType) {
  return (
    <Box {...bem("")}>
      <table>
        <tbody>
          {accessibilityTable?.map((item) => {
            return (
              <tr key={item.guideline}>
                <td>
                  <Status level={item.level} type={item.status} />
                </td>
                <td>
                  <Heading level={3} size="xs">
                    {item.guideline}
                  </Heading>
                  <Paragraph size="sm">{item.description}</Paragraph>
                </td>
                <td>{item.extraInfo && <PortableText value={item.extraInfo} />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}
