import React from "react";
import { BodyText, Heading } from "@aidnas/design-system";

import Box from "../Box";

import styles from "./Styles.module.scss";

import PortableText from "@/components/PortableText";
import BEMHelper from "@/lib/bem";
import { type PageQueryResult } from "@/types/sanity";

const bem = BEMHelper(styles);

export type CalloutProps = NonNullable<NonNullable<PageQueryResult>["callout"]>;

export default function Callout({ body, title, variant = "warning" }: CalloutProps) {
  return (
    <Box noShadow sentiment={variant} {...bem("")}>
      <Heading level={5} size="sm" {...bem("title")}>
        {title}
      </Heading>
      <BodyText size="sm" {...bem("body")}>
        {body && <PortableText value={body} />}
      </BodyText>
    </Box>
  );
}
