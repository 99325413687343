import { Heading } from "@aidnas/design-system";
import { PortableText } from "next-sanity";

import Box from "../Box";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { AnnotationList as AnnotationListType } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function AnnotationList({ rows, showNumbers }: AnnotationListType) {
  return (
    <Box noPadding noShadow transparent {...bem("", null, "annotationlist")}>
      <table {...bem("table")}>
        <tbody>
          {rows?.map((row, i) => {
            return (
              <tr key={row.title}>
                {showNumbers && (
                  <td {...bem("number")}>
                    <span>{i + 1}</span>
                  </td>
                )}
                <td>
                  <Heading level={3} size="xs">
                    {row.title}
                  </Heading>
                </td>
                <td>{row.value && <PortableText value={row.value} />}</td>
                <td>{row.description && <PortableText value={row.description} />}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
}
