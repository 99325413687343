import PortableText from "../PortableText";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { Columns } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function ColumnsComponent({ left, right }: Columns) {
  return (
    <div {...bem("")}>
      {left ? <PortableText value={left || []} {...bem("col")} /> : <div {...bem("col")} />}
      {right ? <PortableText value={right || []} {...bem("col")} /> : <div {...bem("col")} />}
    </div>
  );
}
