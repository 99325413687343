import { CheckCircleIcon, CriticalIcon, TimeIcon, WarningIcon } from "@aidnas/design-system";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";

const bem = BEMHelper(styles);

type StatusType = "success" | "fail" | "action_required";
type StatusLevel = "A" | "AA" | "AAA";

interface StatusIconProps {
  type?: StatusType;
}

const Icon = ({ type }: StatusIconProps) => {
  if (!type)
    return (
      <>
        <TimeIcon /> Unknown
      </>
    );

  if (type === "success") {
    return (
      <>
        <CheckCircleIcon /> Success
      </>
    );
  }

  if (type === "fail") {
    return (
      <>
        <CriticalIcon /> Fail
      </>
    );
  }

  if (type === "action_required") {
    return (
      <>
        <WarningIcon /> Action required
      </>
    );
  }

  return null;
};

interface StatusProps {
  type?: StatusType;
  level?: StatusLevel;
}

export const Status = ({ level, type }: StatusProps) => {
  return (
    <span {...bem("", type)}>
      <span {...bem("tag", type)}>
        <Icon type={type} />
      </span>
      {level && <span {...bem("level")}>{level}</span>}
    </span>
  );
};
