import { HTMLProps } from "react";
import { BodyText, LinkIcon, OpenInNewIcon, Paragraph } from "@aidnas/design-system";
import Link from "next/link";

import Box from "../Box";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import { LinkBlock as LinkBlockType } from "@/types/sanity";

const bem = BEMHelper(styles);

export type LinkBlockProps = LinkBlockType &
  HTMLProps<HTMLAnchorElement> & {
    reference?: {
      section: string;
      slug: string;
    };
  };

export default function LinkBlock(props: LinkBlockProps) {
  const href = props.isExternalLink
    ? props.href
    : `/${props.reference?.section}/${props.reference?.slug}`;

  if (!href) return null;

  return (
    <Box noPadding noShadow transparent {...bem()}>
      <Link
        href={href}
        rel="noopener noreferrer"
        target={props.isExternalLink ? "_blank" : "_self"}
        {...bem("link")}
      >
        {props.isExternalLink ? (
          <OpenInNewIcon color="var(--text-on-neutral)" size={20} />
        ) : (
          <LinkIcon color="var(--text-on-neutral)" size={20} />
        )}

        <span {...bem("content")}>
          <BodyText color="text-on-neutral" size="sm">
            <strong>{props.title}</strong>
          </BodyText>
          <Paragraph color="text-on-neutral" size="sm">
            {props.description}
          </Paragraph>
        </span>
      </Link>
    </Box>
  );
}
