import { Paragraph } from "@aidnas/design-system";
import MarkdownToJSX from "markdown-to-jsx";

import InlineCode from "../InlineCode";

type Props = {
  value: string;
};

export default function Markdown({ value }: Props) {
  return (
    <MarkdownToJSX
      options={{
        overrides: {
          p: {
            component: Paragraph,
            props: {
              size: "sm",
            },
          },
          code: {
            component: InlineCode,
          },
        },
      }}
    >
      {value}
    </MarkdownToJSX>
  );
}
