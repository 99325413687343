import { getDesignTokens, Heading } from "@aidnas/design-system";
import { Table } from "@aidnas/design-system-bandaid";
import { vercelStegaClean } from "@vercel/stega";
import { keys } from "lodash";

import Box from "../Box";
import InlineCode from "../InlineCode";
import PortableText from "../PortableText";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import type { DesignTokens } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function DesignTokensComponent({ intro, sections, title }: DesignTokens) {
  return (
    <Box {...bem("")}>
      <Heading level={3} size="md">
        {title}
      </Heading>

      {intro && <PortableText value={intro} />}

      {sections?.map((section) => {
        const allTokens = section.tokenType
          ? getDesignTokens()[vercelStegaClean(section.tokenType)]
          : [];
        const pattern = new RegExp(
          (vercelStegaClean(section.matchPattern) || "*").replaceAll("*", ".*")
        );
        const matchedTokes = keys(allTokens)
          .filter((t) => t.match(pattern))
          ?.map((token) => ({ token }));

        return (
          <div key={section._key}>
            <Heading level={4} size="sm">
              {section.title}
            </Heading>

            {section.intro && <PortableText value={section.intro} />}

            <Table
              columns={[
                {
                  title: "Token",
                  dataIndex: "token",
                  key: "token",
                  render: (value) => (
                    <div {...bem("color")} style={{ background: `var(--${value})` }} />
                  ),
                },
                {
                  title: "Name",
                  dataIndex: "token",
                  key: "name",
                  render: (value) => <InlineCode copy>{`--${value}`}</InlineCode>,
                },
                {
                  title: "Role",
                  dataIndex: "token",
                  key: "role",
                  render: (value) => {
                    const role = section.editorialContent?.find(
                      ({ token }) => vercelStegaClean(token) === value
                    )?.role;

                    if (!role) {
                      return "";
                    }

                    return <PortableText value={role} />;
                  },
                },
                {
                  title: "Sentiment",
                  dataIndex: "token",
                  key: "sentiment",
                  render: (value) => {
                    const sentiment = section.editorialContent?.find(
                      ({ token }) => vercelStegaClean(token) === value
                    )?.sentiment;

                    return sentiment && <InlineCode>{sentiment}</InlineCode>;
                  },
                },
              ]}
              dataSource={matchedTokes}
              pagination={false}
              virtual={false}
            />
          </div>
        );
      })}
    </Box>
  );
}
