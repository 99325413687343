/**
 * This component uses Portable Text to render a post body.
 *
 * You can learn more about Portable Text on:
 * https://www.sanity.io/docs/block-content
 * https://github.com/portabletext/react-portabletext
 * https://portabletext.org/
 *
 */

import { Heading, HeadingProps, Paragraph, ParagraphProps } from "@aidnas/design-system";
import { Divider } from "@aidnas/design-system-bandaid";
import dynamic from "next/dynamic";
import { PortableText as NextSanityPortableText, type PortableTextComponents } from "next-sanity";

import AccessibilityTable from "../AccessibilityTable";
import AnnotationList from "../AnnotationList";
import Box from "../Box";
import CalloutComponent, { CalloutProps } from "../Callout";
import ChangelogComponent from "../Changelog";
import ColumnsComponent from "../Columns";
import ComponentHealthComponent from "../ComponentHealth";
import ComponentPropsComponent from "../ComponentProps";
import DesignTokensComponent from "../DesignTokens";
import ImageBlockComponent from "../ImageBlock";
import InlineCode from "../InlineCode";
import LinkBlock, { LinkBlockProps } from "../LinkBlock";

import styles from "./Styles.module.scss";

import Link from "@/components/Link";
import BEMHelper from "@/lib/bem";
import {
  AccessibilityTable as AccessibilityTableType,
  AnnotationList as AnnotationListType,
  Changelog,
  Columns,
  ComponentHealth,
  ComponentProps,
  DesignTokens,
  ImageBlock,
  Link as LinkType,
  Playground,
  SystemIcons,
} from "@/types/sanity";

const PlaygroundComponent = dynamic(() => import("@/components/Playground"), {
  loading: () => <Box noShadow>Loading</Box>,
});
const SystemIconsComponent = dynamic(() => import("@/components/SystemIcons"), {
  loading: () => <Box noShadow>Loading...</Box>,
});

const bem = BEMHelper(styles);

type Block<T = any> = { _key: string } & T;

type Props = {
  className?: string;
  value: any[];
  size?: ParagraphProps["size"];
  headingSizeOffset?: 0 | 1 | 2;
};

function offsetHeadingSize(size: HeadingProps["size"], offset: Props["headingSizeOffset"]) {
  const sizes = ["xl", "lg", "md", "sm", "xs", "xxs"];

  if (!offset) {
    return size;
  }

  const offsetSize = sizes[
    Math.min(sizes.indexOf(size) + offset, sizes.length - 1)
  ] as HeadingProps["size"];

  return offsetSize;
}

function getPortableText(
  overrideComponents?: (
    size?: ParagraphProps["size"],
    headingSizeOffset?: Props["headingSizeOffset"]
  ) => PortableTextComponents
) {
  return function PortableText({ className, headingSizeOffset = 0, size, value }: Props) {
    const components = overrideComponents?.(size, headingSizeOffset) ?? {};

    const ptComponents: PortableTextComponents = {
      ...(components ?? {}),
      block: components?.block || {},
      types: components?.types || {},
      marks: {
        ...(components?.marks || {}),
        link: ({
          children,
          value,
        }: {
          children: any;
          value?: LinkType & {
            reference: {
              type: "brandDocPage" | null;
              section: string | null;
              slug: string | null;
              site: string | null;
            };
          };
        }) => {
          if (value?.href) {
            return (
              <a href={value?.href} rel="noreferrer noopener">
                <>{children}</>
              </a>
            );
          }

          const href = `/${value?.reference?.section}/${value?.reference.slug}`;

          return <Link href={href}>{children}</Link>;
        },
      },
    };

    return (
      <div {...bem("", null, className)}>
        <NextSanityPortableText components={ptComponents} value={value} />
      </div>
    );
  };
}

/**
 * A portable text renderer that handles links, but not much else
 */
export const SimplePortableText = getPortableText();

/**
 * The full portable text handling for the Aidn brand sites – this one
 * handles all the different block types, inline code styles++
 */
const PortableText = getPortableText((size, headingSizeOffset) => {
  return {
    block: {
      h1: ({ children }) => (
        <div {...bem("h1")}>
          <Heading level={1} size={offsetHeadingSize("xl", headingSizeOffset)}>
            {children}
          </Heading>
        </div>
      ),
      h2: ({ children }) => (
        <div {...bem("h2")}>
          <Heading level={2} size={offsetHeadingSize("lg", headingSizeOffset)}>
            {children}
          </Heading>
        </div>
      ),
      h3: ({ children }) => (
        <div {...bem("h3")}>
          <Heading level={3} size={offsetHeadingSize("md", headingSizeOffset)}>
            {children}
          </Heading>
        </div>
      ),
      h4: ({ children }) => (
        <div {...bem("h4")}>
          <Heading level={4} size={offsetHeadingSize("sm", headingSizeOffset)}>
            {children}
          </Heading>
        </div>
      ),
      h5: ({ children }) => (
        <div {...bem("h5")}>
          <Heading level={5} size={offsetHeadingSize("xs", headingSizeOffset)}>
            {children}
          </Heading>
        </div>
      ),
      normal: ({ children }) => (
        <div>
          <Paragraph {...bem("p")} size={size}>
            {children}
          </Paragraph>
        </div>
      ),
    },
    types: {
      divider: () => {
        return <Divider type="horizontal" {...bem("divider")} />;
      },
      playground: ({ value }: { value: Block<Playground> }) => {
        return <PlaygroundComponent {...value} />;
      },
      systemIcons: ({ value }: { value: Block<SystemIcons> }) => {
        return <SystemIconsComponent {...value} />;
      },
      designTokens: ({ value }: { value: Block<DesignTokens> }) => {
        return <DesignTokensComponent {...value} />;
      },
      imageBlock: ({ value }: { value: Block<ImageBlock> }) => {
        return <ImageBlockComponent {...value} />;
      },
      linkBlock: ({ value }: { value: Block<LinkBlockProps> }) => {
        return <LinkBlock {...value} />;
      },
      componentProps: ({ value }: { value: Block<ComponentProps> }) => {
        return <ComponentPropsComponent {...value} />;
      },
      componentHealth: ({ value }: { value: Block<ComponentHealth> }) => {
        return <ComponentHealthComponent {...value} />;
      },
      changelog: ({ value }: { value: Block<Changelog> }) => {
        return <ChangelogComponent {...value} />;
      },
      accessibilityTable: ({ value }: { value: Block<AccessibilityTableType> }) => {
        return <AccessibilityTable {...value} />;
      },
      annotationList: ({ value }: { value: Block<AnnotationListType> }) => {
        return <AnnotationList {...value} />;
      },
      columns: ({ value }: { value: Block<Columns> }) => {
        return <ColumnsComponent {...value} />;
      },
      docCallout: ({ value }: { value: Block<CalloutProps> }) => {
        return <CalloutComponent {...value} />;
      },
    },
    marks: {
      code: ({ children }) => <InlineCode>{children}</InlineCode>,
    },
    unknownType: (props) => {
      return <pre>{JSON.stringify(props, null, 2)}</pre>;
    },
  };
});

export default PortableText;
