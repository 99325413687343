import { ReactNode } from "react";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";

const bem = BEMHelper(styles);

type Props = {
  children: ReactNode;
  className?: string;
  transparent?: boolean;
  noPadding?: boolean;
  noShadow?: boolean;
  sentiment?: "info" | "warning";
};

export default function Box({
  children,
  className,
  noPadding = false,
  noShadow = false,
  sentiment,
  transparent = false,
  ...rest
}: Props) {
  return (
    <div {...bem("", { transparent, noPadding, noShadow, sentiment }, className)} {...rest}>
      {children}
    </div>
  );
}
