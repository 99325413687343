import { Heading } from "@aidnas/design-system";
import { Table } from "@aidnas/design-system-bandaid";

import Box from "../Box";
import PortableText from "../PortableText";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import formatDate from "@/lib/format";
import type { Changelog } from "@/types/sanity";

const bem = BEMHelper(styles);

export default function ChangelogComponent({ items }: Changelog) {
  return (
    <Box {...bem("")}>
      <Heading level={3} size="md">
        Changelog
      </Heading>

      <Table
        columns={[
          {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (value) => formatDate(value),
          },
          {
            title: "Number",
            dataIndex: "number",
            key: "number",
          },
          {
            title: "Notes",
            dataIndex: "notes",
            key: "notes",
            ...bem("notes"),
            render: (value) => <PortableText value={value} {...bem("notes")} />,
          },
        ]}
        dataSource={items}
        pagination={false}
        virtual={false}
      />
    </Box>
  );
}
