import componentDocgen from "@aidnas/design-system/component-docs.json";
import { Alert, Table } from "@aidnas/design-system-bandaid";
import { vercelStegaClean } from "@vercel/stega";
import { orderBy, values } from "lodash";

import Box from "../Box";
import InlineCode from "../InlineCode";
import Markdown from "../Markdown";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import type { ComponentProps } from "@/types/sanity";

const bem = BEMHelper(styles);

const chunkLines = (text: string) => {
  return text.split("\n").reduce<{ type: string; lines: string[] }[]>((acc, line) => {
    const [, type = "markdown"] = line.match(/^@(\w+) /) || [];

    // ignore @see lines
    if (type === "see") {
      return acc;
    }

    // if the previous line was of the same type, append to the previous chunk
    if (acc?.[acc.length - 1]?.type === type) {
      return [...acc.slice(0, -1), { type, lines: [...acc[acc.length - 1].lines, line] }];
    }

    // otherwise, create a new chunk
    return [...acc, { type, lines: [line] }];
  }, []);
};

type TableRecord = {
  type: { raw?: string; name?: string };
  required?: boolean;
};

export default function ComponentPropsComponent({ component }: ComponentProps) {
  const props = (
    componentDocgen.find(({ displayName }) => vercelStegaClean(component) === displayName) ?? {}
  )?.props;

  // Get props sorted by required (desc) and name (asc)
  const sortedProps = props && orderBy(values(props), ["required", "name"], ["desc", "asc"]);

  return (
    <Box {...bem("")} data-sanity-edit-target>
      {!props && (
        <Alert
          description={`Unable to resolve component props for ${component}`}
          message="Component props not found"
          type="warning"
        />
      )}

      {props && (
        <Table
          columns={[
            {
              title: "Property",
              dataIndex: "name",
              key: "name",
              render: (value, { required }: TableRecord) => (
                <InlineCode>
                  {value}
                  {required ? "*" : ""}
                </InlineCode>
              ),
            },
            {
              title: "Description",
              dataIndex: "description",
              key: "description",
              render: (value, record: TableRecord) => {
                const type = record.type.raw || record.type.name;

                const chunks = chunkLines(value);

                return (
                  <>
                    {chunks
                      .filter(({ type }) => type === "markdown")
                      .map(({ lines }, i) => (
                        <Markdown key={i} value={lines.join("\n")} />
                      ))}

                    <div {...bem("type")}>
                      <InlineCode>{type}</InlineCode>
                    </div>

                    {chunks
                      .filter(({ type }) => type === "param")
                      .map(({ lines }, i) => (
                        <dl key={i} {...bem("params-list")}>
                          {lines.map((line) => {
                            const [, name, description] = line.match(/^@param (\w+) (.+)/) || [];

                            return (
                              <>
                                <dt>
                                  <InlineCode>{name}</InlineCode>
                                </dt>
                                <dd>
                                  <Markdown value={description} />
                                </dd>
                              </>
                            );
                          })}
                        </dl>
                      ))}

                    {chunks
                      .filter(({ type }) => !["param", "markdown"].includes(type))
                      .map(({ lines }) =>
                        lines.map((line, i) => (
                          <div key={i} {...bem("doc-keyword")}>
                            <InlineCode>{line}</InlineCode>
                          </div>
                        ))
                      )}
                  </>
                );
              },
            },
            {
              title: "Default",
              dataIndex: "defaultValue",
              key: "defaultValue",
              render: (value) => {
                // A few different value types (null, undefined, booleans, dates and
                // numbers) should not be quoted. Any other type (?) is a string and
                // should be wrapped in quotes
                const literalValue =
                  // null, undefined and booleans
                  ["null", "undefined", "false", "true"].includes(value?.value) ||
                  // numbers
                  !Number.isNaN(Number(value?.value)) ||
                  // dates
                  !Number.isNaN(new Date(value?.value).valueOf());

                return (
                  <InlineCode>
                    {!value?.value ? (
                      "undefined"
                    ) : (
                      <>{literalValue ? value.value : `"${value?.value}"`}</>
                    )}
                  </InlineCode>
                );
              },
            },
          ]}
          dataSource={sortedProps}
          pagination={false}
          virtual={false}
        />
      )}
    </Box>
  );
}
