import { CopyIcon } from "@aidnas/design-system";
import { vercelStegaSplit } from "@vercel/stega";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";

const bem = BEMHelper(styles);

type Props = { children?: any; copy?: boolean; wrap?: boolean; className?: string };

export default function InlineCode({ children, className, copy = false, wrap = false }: Props) {
  const { cleaned, encoded } = vercelStegaSplit(`${children}`);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(`${cleaned}`);
    } catch (e: any) {
      console.warn("Clipboard failed. Most likely blocked by browser:", e.message);
    }
  };

  if (copy) {
    return (
      <button onClick={handleCopy} {...bem("", { copy, wrap }, className)}>
        <div>{children}</div>
        <CopyIcon />
        <span style={{ display: "none" }}>{encoded}</span>
      </button>
    );
  }

  return (
    <div {...bem("", null, className)}>
      <div>{children}</div>
    </div>
  );
}
