import { Heading } from "@aidnas/design-system";
import { Tag } from "@aidnas/design-system-bandaid";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { vercelStegaClean } from "@vercel/stega";

import Box from "../Box";

import styles from "./Styles.module.scss";

import BEMHelper from "@/lib/bem";
import type { ComponentHealth } from "@/types/sanity";

const bem = BEMHelper(styles);

const mapColor = (
  status: ComponentHealth["accessible"]
): "success" | "error" | "default" | "warning" => {
  switch (vercelStegaClean(status)) {
    case "positive":
      return "success";

    case "caution":
      return "warning";

    case "critical":
      return "error";

    default:
      return "default";
  }
};

const mapIcon = (status: ComponentHealth["accessible"]) => {
  switch (vercelStegaClean(status)) {
    case "positive":
      return <CheckCircleOutlined />;

    case "caution":
      return <ExclamationCircleOutlined />;

    case "critical":
      return <CloseCircleOutlined />;

    default:
      return <MinusCircleOutlined />;
  }
};

export default function ComponentPropsComponent({
  accessible,
  allPlatforms,
  code,
  design,
  documentation,
  responsive,
}: ComponentHealth) {
  return (
    <Box {...bem("")}>
      <Heading level={3} size="md">
        Component health
      </Heading>

      <div {...bem("tags")}>
        <Tag color={mapColor(design)} icon={mapIcon(design)} {...bem("tag")}>
          Design
        </Tag>

        <Tag color={mapColor(documentation)} icon={mapIcon(documentation)} {...bem("tag")}>
          Documentation
        </Tag>

        <Tag color={mapColor(code)} icon={mapIcon(code)} {...bem("tag")}>
          Code
        </Tag>

        <Tag color={mapColor(responsive)} icon={mapIcon(responsive)} {...bem("tag")}>
          Responsive
        </Tag>

        <Tag color={mapColor(accessible)} icon={mapIcon(accessible)} {...bem("tag")}>
          Accessible
        </Tag>

        <Tag color={mapColor(allPlatforms)} icon={mapIcon(allPlatforms)} {...bem("tag")}>
          All platforms
        </Tag>
      </div>
    </Box>
  );
}
