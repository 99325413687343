import { format } from "date-fns";

export default function formatDate(date: string | Date) {
  if (!date) {
    return;
  }

  const d = new Date(date);

  return format(d, "MMM d, Y");
}
